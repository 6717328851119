import { graphql, useStaticQuery } from 'gatsby'
import { PostListItemType } from 'types/PostItem.types'
export const usePosts = (): PostListItemType[] => {
    const data = useStaticQuery(graphql`
        query getPostList {
            allMarkdownRemark(
                sort: {
                    order: DESC
                    fields: [frontmatter___date, frontmatter___title]
                }
            ) {
                edges {
                    node {
                        id
                        excerpt
                        timeToRead
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            summary
                            date(formatString: "YYYY.MM.DD.")
                            categories
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 200
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    return data.allMarkdownRemark.edges
}
