import React, { FunctionComponent } from 'react'
import SEO from 'components/Common/SEO'
import styled from '@emotion/styled/macro'
import tw from 'twin.macro'

import { PageProps } from 'gatsby'
import queryString, { ParsedQuery } from 'query-string'
import Layout from 'components/Common/Layout'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import { useCategories } from 'hooks/useCategories'
import { usePosts } from 'hooks/usePosts'
import useInfiniteScroll, {
    useInfiniteScrollType,
} from 'hooks/useInfiniteScroll'
import { PostListItemType } from 'types/PostItem.types'
import PostItem from 'components/Main/PostItem'
import Bio from 'components/Common/Bio'

const PostList = styled.div`
    ${tw`container flex flex-col m-5 justify-between`}
`

export const Index: FunctionComponent<PageProps> = ({
    location,
}: PageProps) => {
    const posts = usePosts()
    const siteMetadata = useSiteMetadata()
    const categories = useCategories()
    const parsed: ParsedQuery<string> = queryString.parse(location.search)
    const selectedCategory: string =
        typeof parsed.category !== 'string' || !parsed.category
            ? 'All'
            : parsed.category
    const { containerRef, postList }: useInfiniteScrollType = useInfiniteScroll(
        selectedCategory,
        siteMetadata.configs.countOfInitialPost,
        posts,
    )
    return (
        <Layout
            siteMetadata={siteMetadata}
            selectedCategory={selectedCategory}
            categories={categories}
        >
            <PostList ref={containerRef}>
                {postList.map(
                    ({
                        node: {
                            id,
                            excerpt,
                            timeToRead,
                            fields: { slug },
                            frontmatter,
                        },
                    }: PostListItemType) => (
                        <PostItem
                            timeToRead={timeToRead}
                            excerpt={excerpt}
                            {...frontmatter}
                            link={slug}
                            key={id}
                        />
                    ),
                )}
            </PostList>
        </Layout>
    )
}
export const Head = SEO
export default Index
