import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled/macro'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PostFrontmatterType } from 'types/PostItem.types'
import Metadata from 'components/Common/Metadata'

type PostItemProps = PostFrontmatterType & {
    excerpt: string
    timeToRead: number
    link: string
}

const Summary = styled.div`
    display: -webkit-box;
    overflow: hidden;
    margin-top: auto;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    opacity: 0.8;
`

const PostItemWrapper = styled(Link)`
    ${tw`flex flex-col rounded-3xl mb-5 justify-start`}
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    transition: 0.3s box-shadow;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
    }
`

const ThumbnailImage = styled(GatsbyImage)`
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
`
const PostItemContent = styled.div`
    ${tw`flex flex-1 flex-col p-4`}
`
const PostItem: FunctionComponent<PostItemProps> = function ({
    timeToRead,
    title,
    date,
    categories,
    excerpt,
    link,
}: PostItemProps) {
    const thumbnail = null
    return (
        <PostItemWrapper to={link}>
            {thumbnail && (
                <ThumbnailImage
                    image={thumbnail.childImageSharp.gatsbyImageData}
                    alt="Post Item Image"
                />
            )}
            <PostItemContent>
                <Metadata
                    title={title}
                    timeToRead={timeToRead}
                    date={date}
                    categories={categories}
                />
                <Summary>{excerpt}</Summary>
            </PostItemContent>
        </PostItemWrapper>
    )
}

export default PostItem
