import { MutableRefObject, useState, useEffect, useRef, useMemo } from 'react'
import { PostListItemType } from 'types/PostItem.types'

export type useInfiniteScrollType = {
    containerRef: MutableRefObject<HTMLDivElement | null>
    postList: PostListItemType[]
}

const useInfiniteScroll = function (
    selectedCategory: string,
    countOfInitialPost: number,
    posts: PostListItemType[],
): useInfiniteScrollType {
    const containerRef: MutableRefObject<HTMLDivElement | null> =
        useRef<HTMLDivElement>(null)
    const observer: MutableRefObject<IntersectionObserver | null> =
        useRef<IntersectionObserver>(null)
    const [count, setCount] = useState<number>(1)

    const postListByCategory = useMemo<PostListItemType[]>(
        () =>
            posts.filter(
                ({
                    node: {
                        frontmatter: { categories },
                    },
                }: PostListItemType) =>
                    selectedCategory !== 'All'
                        ? categories.includes(selectedCategory)
                        : true,
            ),
        [selectedCategory],
    )

    useEffect(() => {
        observer.current = new IntersectionObserver((entries, observer) => {
            if (!entries[0].isIntersecting) return

            setCount(value => value + 1)
            observer.unobserve(entries[0].target)
        })
    }, [])

    useEffect(() => setCount(1), [selectedCategory])

    useEffect(() => {
        if (
            countOfInitialPost * count >= postListByCategory.length ||
            containerRef.current === null ||
            containerRef.current.children.length === 0 ||
            observer.current === null
        )
            return

        observer.current.observe(
            containerRef.current.children[
                containerRef.current.children.length - 1
            ],
        )
    }, [count, selectedCategory])

    return {
        containerRef,
        postList: postListByCategory.slice(0, count * countOfInitialPost),
    }
}

export default useInfiniteScroll
