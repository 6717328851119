import { graphql, useStaticQuery } from 'gatsby'
import { CategoryType } from 'types/Category.types'
export const useCategories = (): CategoryType[] => {
    const data = useStaticQuery(graphql`
        {
            categoriesGroup: allMarkdownRemark {
                group(field: frontmatter___categories) {
                    fieldValue
                    totalCount
                }
            }
        }
    `)
    return data.categoriesGroup.group
}
